<template>
  <div>
    <userData />
    <div class="report">
      <div v-loading="loading" v-if="tableData">
        <div v-for="(item, i) in tableData" :key="i" class="tables">
          <table border="1" class="report_table">
            <tr>
              <td class="tip">平行志愿{{ word[i] }}</td>
              <td colspan="4" class="name">
                <span>[{{item.school_id}}]{{ item.name }}</span>
                {{ item.city_name }}
                同意调剂
              </td>
            </tr>
            <tr class="title_r">
              <td colspan="2" width="100px">专业序号</td>
              <td width="540px">专业名称</td>
              <td width="190px">招生计划</td>
              <td width="290px">收费标准</td>
            </tr>
            <tr v-for="(sp, i) in item.specialty" :key="i">
              <td colspan="2" width="100px">专业{{ i + 1 }}</td>
              <td width="540px">[{{sp.specialty_id | interpo}}]{{ sp.specialty_name }}</td>
              <td width="190px">{{ sp.plan_num }}</td>
              <td width="290px">{{ sp.tuition }}</td>
            </tr>
          </table>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import userData from "@/components/userData";
//time: 2020/10/30
export default {
  name: "Report",
  
  data() {
    return {
      loading: true,
      batch: 3,
      form: {
        is_wenli: 2,
        score: 580,
      },
      word: ["一", "二", "三", "四", "五", "六", "七", "八", "九",'十','十一','十二'],
      cha: 30,
      tableData: [],
    };
  },
  mounted() {
    this.getReport();
  },
  components: {
    userData,
  },

  methods: {
    getReport() {
      this.loading = true;
      this.$fecth
        .post("volunteer/getReport", {
          report_id: this.$route.query.report_id,
        })
        .then((res) => {
          console.log(res.data);
          this.loading = false;
          this.tableData = JSON.parse(res.data.table);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.report {
  width: 100%;
  background: white;
  margin-top: 20px;
  padding: 30px;
  .title {
    padding: 1px;
    line-height: 30px;
    letter-spacing: 1px;
    text-indent: 2rem;
    color: #5d5e5f;
  }

  .refresh {
    background: #409eff;
    color: white;
    display: inline-block;
    border-radius: 30px;
    padding: 8px 30px;
    margin-bottom: 20px;
    img {
      position: relative;
      top: 2px;
    }
  }

  .report_table {
    border-collapse: collapse;
    margin-bottom: 40px;
    line-height: 35px;
    text-align: center;

    .name {
      position: relative;

      span {
        position: absolute;
        left: 88px;
      }

      text-align: right;
      text-indent: 2rem;
      padding-right: 80px;

      img {
        width: 15px;
        height: 15px;
        margin-left: 30px;
        position: relative;
        top: 1px;
      }
    }
  }

  .tables {
    border: #e1e7eb;

    .tip {
      width: 150px;
      background: #1f98dc;
      color: white;
    }

    .title_r {
      background: #e1e7eb;
    }
  }
}
</style>

<style lang="less">
.el-icon-my-export {
  background-size: cover;
}
.el-icon-my-export:before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

//如果直接使用字体图片
//直接在before属性设置对应的content就行
.el-icon-my-export {
  font-size: 16px;
}
.el-icon-my-export:before {
  content: "\e611";
}
</style>